const locationKeys = [
  'britLib',
  'britMus',
  'oxford',
  'nhm',
  'manchester',
  'zoo',
  'beatles',
  'londonTower',
  'greenwich',
  'hobbit',
];

const courierKeys = ['us'];

export { locationKeys, courierKeys };

import { Translation } from './types';

const translation: Translation = {
  headText: 'I would like a',
  postcard: 'postcard',
  via: 'via',
  locations: {
    britLib: 'British Library',
    britMus: 'British Museum',
    oxford: 'Oxford University',
    nhm: 'Natual History Museum',

    manchester: 'Manchester Arts Gallery',
    zoo: 'London Zoo',
    beatles: 'Beatles Museum',
    londonTower: 'London Tower',
    greenwich: 'Greenwich',
    hobbit: 'Hobbit',
  },
  couriers: {
    gb: 'Royal Mail',
    us: 'USPS',
  },
  selections: 'Selections',
  selectionLimits: 'subject to availabilities',
  address: 'Address',
  addressNotice: `pleas fill in with languages local to the destination`,
  name: '(nick)name',
  firstLine: 'first line',
  secondLine: 'second line',
  city: 'city',
  stateOrProvince: 'state/province',
  zipCode: 'zip/postal code',
  country: 'country',
  optional: 'optional',
};

export default translation;

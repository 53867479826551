import { assign, createMachine } from 'xstate';
import { save } from '../data/firebase';

type ContextType = {
  error: null | string;
};

const postMachine = createMachine<ContextType>(
  {
    id: 'postMachine',
    initial: 'unsent',
    context: {
      error: null,
    },
    states: {
      unsent: {
        on: {
          post: {
            target: 'loading',
            actions: 'clearError',
          },
        },
      },
      loading: {
        invoke: {
          src: 'post',
          onDone: 'done',
          onError: 'error',
        },
      },
      done: {
        type: 'final',
      },
      error: {
        always: {
          target: 'unsent',
          actions: ['assignError'],
        },
      },
    },
  },
  {
    actions: {
      assignError: assign({
        error: (context, event) => {
          console.log('error', event);
          return 'something wrong, please try again';
        },
      }),
      clearError: assign({
        error: context => null,
      }),
    },
    services: {
      post: async (context, event) => save(event.payload),
    },
  }
);

export default postMachine;

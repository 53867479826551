import { assign, createMachine } from 'xstate';

export type SelectionType = {
  courier: string;
  location: string;
};

type SelectionMachineContextType = {
  selections: Array<SelectionType>;
  showAddressForm: boolean;
};

type SelectionMachineEventType =
  | {
      type: 'add';
      payload: SelectionType;
    }
  | {
      type: 'remove';
      index: number;
    }
  | {
      type: 'show';
    };

const selectionMachine = createMachine<
  SelectionMachineContextType,
  SelectionMachineEventType
>(
  {
    id: 'selection',
    initial: 'unselected',
    context: {
      selections: [],
      showAddressForm: false,
    },
    states: {
      evaluating: {
        always: [
          {
            target: 'unselected',
            cond: 'nothingSelected',
          },
          {
            target: 'normal',
            cond: 'normal',
          },
          {
            target: 'max',
            cond: 'maxSelected',
          },
        ],
      },
      unselected: {
        on: {
          add: {
            target: 'evaluating',
            actions: 'select',
          },
        },
      },
      normal: {
        on: {
          add: {
            target: 'evaluating',
            actions: 'select',
          },
          remove: {
            target: 'evaluating',
            actions: 'unselect',
          },
          show: {
            target: 'normal',
            actions: 'showAddressForm',
          },
        },
      },
      max: {
        on: {
          remove: {
            target: 'evaluating',
            actions: 'unselect',
          },
          show: {
            target: 'max',
            actions: 'showAddressForm',
          },
        },
      },
    },
  },
  {
    guards: {
      nothingSelected: context => context.selections.length === 0,
      normal: context =>
        context.selections.length < 2 && context.selections.length > 0,
      maxSelected: context => context.selections.length === 2,
    },
    actions: {
      select: assign({
        selections: (context, event) => {
          if (event.type !== 'add') {
            return context.selections;
          }
          return [...context.selections, event.payload];
        },
      }),
      unselect: assign({
        selections: (context, event) => {
          if (event.type !== 'remove') {
            return context.selections;
          }

          return [
            ...context.selections.slice(0, event.index),
            ...context.selections.slice(event.index + 1),
          ];
        },
      }),
      showAddressForm: assign({
        showAddressForm: context => true,
      }),
    },
  }
);

export default selectionMachine;

import * as React from 'react';
import './src/data/firebase';
import './src/utils/i18n';
import './src/styles/global.css';
import smoothscroll from 'smoothscroll-polyfill';
import MachineContextProvider from './src/context/machineContext';

// kick off the polyfill!
smoothscroll.polyfill();

export const wrapRootElement = ({ element }) => (
  <MachineContextProvider>{element}</MachineContextProvider>
);

import { assign, createMachine } from 'xstate';

function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const pickerMachine = (upperLimit: number, current = 0) =>
  createMachine(
    {
      id: 'picker',
      context: {
        lowerLimit: 0,
        upperLimit,
        current,
      },
      type: 'parallel',
      states: {
        decrement: {
          initial: 'evaluating',
          states: {
            enabled: {
              on: {
                decrement: {
                  target: 'evaluating',
                  actions: 'decrement',
                },
              },
            },
            disabled: {
              on: {
                increment: {
                  target: 'evaluating',
                },
              },
            },
            evaluating: {
              always: [
                {
                  target: 'enabled',
                  cond: ({ current, lowerLimit }) => current > lowerLimit,
                },
                { target: 'disabled' },
              ],
            },
          },
        },
        increment: {
          initial: 'evaluating',
          states: {
            enabled: {
              on: {
                increment: {
                  target: 'evaluating',
                  actions: 'increment',
                },
              },
            },
            disabled: {
              on: {
                decrement: {
                  target: 'evaluating',
                },
              },
            },
            evaluating: {
              always: [
                {
                  target: 'enabled',
                  cond: ({ current, upperLimit }) => current < upperLimit,
                },
                { target: 'disabled' },
              ],
            },
          },
        },
      },
      on: {
        random: {
          target: ['decrement.evaluating', 'increment.evaluating'],
          actions: 'randomize',
        },
      },
    },
    {
      actions: {
        increment: assign({
          current: ({ current }) => current + 1,
        }),
        decrement: assign({
          current: ({ current }) => current - 1,
        }),
        randomize: assign({
          current: ({ lowerLimit, upperLimit }) =>
            getRandomInt(lowerLimit, upperLimit),
        }),
      },
      guards: {},
    }
  );

export { pickerMachine };

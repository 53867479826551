import { Translation } from './types';

const translation: Translation = {
  headText: '我想要一张',
  via: '交由',
  postcard: '为主题的',
  locations: {
    britLib: '大英图书馆',
    britMus: '大英博物馆',
    oxford: '牛津大学',
    nhm: '自然历史博物馆',

    manchester: '曼彻斯特美术馆',
    zoo: '伦敦动物园',
    beatles: '披头士博物馆',
    londonTower: '伦敦塔',
    greenwich: '格林尼治',
    hobbit: '霍比特人',
  },
  couriers: {
    gb: '皇家邮政',
    us: '美国邮政',
  },
  selections: '已选',
  selectionLimits: '最终选择或因库存状况变动',
  address: '投递信息',
  addressNotice: '请用目的地语言填写地址',
  name: '昵称',
  firstLine: '地址第一行',
  secondLine: '地址第二行',
  city: '城市',
  stateOrProvince: '省份/州',
  zipCode: '邮编',
  country: '国家',
  optional: '选填',
};
export default translation;

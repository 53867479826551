import * as React from 'react';
import { Interpreter } from 'xstate';
import { createContext, ReactNode } from 'react';
import { useInterpret } from '@xstate/react';
import { courierKeys, locationKeys } from '../data/constants';
import { pickerMachine } from '../components/common/scrollablePicker/pickerMachine';
import _selectionMachine from '../machines/selectionMachine';
import _postMachine from '../machines/postMachine';

type Services = {
  locationMachine: Interpreter<any, any, any, any, any>;
  courierMachine: Interpreter<any, any, any, any, any>;
  selectionMachine: Interpreter<any, any, any, any, any>;
  postMachine: Interpreter<any, any, any, any, any>;
};

export const AppMachineContext = createContext<Services>({} as Services);

export default function MachineContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const locationMachine = useInterpret(pickerMachine(locationKeys.length - 1));
  const courierMachine = useInterpret(pickerMachine(courierKeys.length - 1));
  const selectionMachine = useInterpret(_selectionMachine);
  const postMachine = useInterpret(_postMachine);

  return (
    <AppMachineContext.Provider
      value={{ locationMachine, courierMachine, selectionMachine, postMachine }}
    >
      {children}
    </AppMachineContext.Provider>
  );
}

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { firebaseConfig } from '../../secret/firebaseConfig';
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable,
} from 'firebase/functions';
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Initialize Firebase
// let firebaseConfig = {};
// if (process.env.LOCAL && process.env.FIREBASE_CONFIG) {
//   firebaseConfig = JSON.parse(process.env.FIREBASE_CONFIG);
// }

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

const SITE_RECAPTCHA_KEY = '6Ld8JgsfAAAAAC54ZN0tn-QF4JjGmprEYrRT1V7v';

if (typeof document !== 'undefined') {
  // self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(SITE_RECAPTCHA_KEY),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true,
  });
}

if (process.env.NODE_ENV) {
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

export const save = httpsCallable(functions, 'save');
